import React, { useCallback, useEffect, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { useUserContext } from "lib/contexts/UserProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { putRequest } from "lib/core/apiClient";
import { useAuthContext } from "lib/contexts/AuthProvider";

interface MarketingConsentProps {
  customClass?: string;
}

const MarketingConsent: React.FC<MarketingConsentProps> = ({ customClass }) => {
  const {
    state: { checkoutUIMetadata },
  } = useCheckoutContext();

  const { t } = useLocale();
  const {
    state: { marketingConsent },
    actions: { setMarketingConsent },
  } = useUserContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const {
    state: { isAuthenticated },
  } = useAuthContext();

  useEffect(() => {
    const updateMarketingConsent = async () => {
      if (marketingConsent === undefined) return;
      if (!Boolean(isAuthenticated)) return;
      try {
        await putRequest("/attributes/v1/account-attributes", {
          attributes: {
            marketing_consent: marketingConsent,
          },
        });
      } catch (err) {
        console.error(err);
      }
    };
    setMarketingConsent(marketingConsent ?? true);
    updateMarketingConsent();
  }, [marketingConsent, isAuthenticated, setMarketingConsent]);

  const renderSalesCommsContext = useCallback(() => {
    switch (merchant?.salesCommsContext) {
      case 1: {
        return t("marketing_user_consent");
      }
      case 2: {
        return t("marketing_user_consent_v2");
      }
      case 3: {
        return t("marketing_user_consent_v3");
      }
      default: {
        return t("marketing_user_consent");
      }
    }
  }, [merchant?.salesCommsContext, t]);

  if (!checkoutUIMetadata?.layout?.enableMarketingFlag) return null;

  return (
    <>
      <div
        className={`flex h-12 w-full flex-row items-center justify-center space-x-2 px-6 pt-7 ${customClass}`}>
        <input
          type="checkbox"
          id="marketing_user_consent"
          name="marketing_user_consent"
          className="h-[14px] w-[14px] cursor-pointer rounded-sm accent-coal-dark"
          checked={marketingConsent}
          onClick={() => {
            setMarketingConsent(!marketingConsent);
          }}
          onChange={() => {}}
        />
        <label htmlFor="marketing_user_consent" className="select-none text-xs font-normal text-coal-light">
          {renderSalesCommsContext()}
        </label>
      </div>
    </>
  );
};

export default React.memo(MarketingConsent);
