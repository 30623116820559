import { Milestone, PROGRESS_BAR_TYPE } from "lib/types/checkout";

export const calculateProgress = (total: number, milestones: Milestone[], type: PROGRESS_BAR_TYPE) => {
  let achievedMilestoneIdx = 0;
  let currentProgress = 0;
  const PART_PERCENTAGE_OF_MILESTONES = 100 / milestones.length;

  // Find the index of the next milestone that has not yet been achieved
  const nextMileStoneToAchieveIdx = milestones.findIndex((milestone) => milestone.milestone_amount > total);
  if (nextMileStoneToAchieveIdx === -1) {
    // All milestones have been achieved
    achievedMilestoneIdx = milestones.length - 1;
    currentProgress = 100;
    if (milestones.length === 1) {
      currentProgress = 100;
    }
  } else {
    // Determine the next milestone to achieve and the previous milestone (if any)
    const nextMileStoneToAchieve = milestones[nextMileStoneToAchieveIdx];
    achievedMilestoneIdx = nextMileStoneToAchieveIdx - 1;

    // Get the amount required to achieve the next milestone, and the last achieved milestone
    let upperToAchieveAmount = nextMileStoneToAchieve.milestone_amount;
    let lowerAchievedAmount = 0;
    if (nextMileStoneToAchieveIdx > 0) {
      lowerAchievedAmount = milestones[nextMileStoneToAchieveIdx - 1].milestone_amount;
    }

    // Normalize the progress between the last achieved milestone and the next milestone
    let normalizedProgress = (total - lowerAchievedAmount) / (upperToAchieveAmount - lowerAchievedAmount);

    if (nextMileStoneToAchieveIdx === 0) {
      // If no milestones have been achieved yet
      currentProgress = PART_PERCENTAGE_OF_MILESTONES * normalizedProgress;
    } else {
      // If at least one milestone has been achieved
      currentProgress = PART_PERCENTAGE_OF_MILESTONES * (nextMileStoneToAchieveIdx + normalizedProgress);
    }
  }

  return { achievedMilestoneIdx, currentProgress };
};
