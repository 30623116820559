import { memo, useMemo } from "react";
import {
  ProgressBarContext,
  ProgressBarProvider,
  useProgressBar,
} from "components/cart/progress-bar/ProgressBarContext";
import { Truck, Percent, Gift } from "react-feather";
import { classNames, interpolateTemplate } from "lib/utils/helpers";
import { PROGRESS_BAR_TYPE } from "lib/types/checkout";
import { calculateProgress } from "components/cart/progress-bar/ProgressHelper";
import RuppeeIcon from "assests/icons/RupeeIcon";

const MILESTONE_TYPES = {
  FREEBIE: "FREEBIE",
  FREE_SHIPPING: "FREE_SHIPPING",
  PERCENT_OFF: "PERCENT_OFF",
  FLAT_OFF: "FLAT_OFF",
};

export const MILESTONE_TO_ICON_MAPPER = {
  [MILESTONE_TYPES.FLAT_OFF]: RuppeeIcon,
  [MILESTONE_TYPES.FREE_SHIPPING]: Truck,
  [MILESTONE_TYPES.PERCENT_OFF]: Percent,
  [MILESTONE_TYPES.FREEBIE]: Gift,
};

type ProgressBarContainerProps = Pick<
  ProgressBarContext,
  "milestones" | "total" | "type" | "completedMessage"
> & {
  children: React.ReactNode;
};
export const ProgressBarContainer = memo(
  ({ children, milestones, type, total, completedMessage }: ProgressBarContainerProps) => {
    const { achievedMilestoneIdx, currentProgress } = useMemo(
      () => calculateProgress(total, milestones, type),
      [total, milestones],
    );
    return (
      <ProgressBarProvider
        type={type}
        total={total}
        milestones={milestones}
        completedMessage={completedMessage}
        achievedMilestoneIdx={achievedMilestoneIdx}
        totalMilestones={milestones.length}
        currentProgress={currentProgress}>
        {children}
      </ProgressBarProvider>
    );
  },
);

export const ProgressBarSteps = memo(({ children }: { children: React.ReactNode }) => {
  const { currentProgress, totalMilestones } = useProgressBar();
  const overlayWidth = totalMilestones === 1 ? 100 / 8 : 50 / totalMilestones;
  return (
    <div className="relative flex w-full">
      <div className="absolute top-[45%] -z-0 my-auto flex h-full w-full">
        <div
          className={classNames("z-1 absolute h-[4.5px] rounded-3xl bg-yay-dark transition-[width]")}
          style={{
            width: `${currentProgress}%`,
          }}
        />
        <div className="z-3 absolute box-content h-[3px] w-full rounded-3xl border-[1px] border-carbon-lighter" />
        {/* <div
          className={classNames("z-5 absolute -right-1 h-2  bg-white")}
          style={{
            width: `${overlayWidth}%`,
          }}
        /> */}
      </div>
      <ul className={classNames("z-10 flex w-full")}>{children}</ul>
    </div>
  );
});

/**
 * Expected template for milestone_header `Add {{price}} more to get a freebie`
 * {{price}} is replaced with the remaining value
 *
 * this can be extended for other templates variables too.
 * make sure to have no space betweend them
 */
export const ProgressBarHeader = memo(({ index }: { index: number }) => {
  const { achievedMilestoneIdx, milestones, total, completedMessage } = useProgressBar();
  const currentMileStone = milestones[index];
  const mileStoneHeader: string = useMemo(
    () =>
      interpolateTemplate(currentMileStone.milestone_header)({
        price: `₹${(currentMileStone.milestone_amount - total).toFixed(2)}`,
      }),
    [total, currentMileStone],
  );
  if (achievedMilestoneIdx === index - 1) {
    return <p className="p-3 pt-0 text-center text-sm font-semibold text-yay-dark">{mileStoneHeader}</p>;
  } else if (achievedMilestoneIdx === milestones.length - 1 && achievedMilestoneIdx === index) {
    return <p className="p-3 pt-0 text-center text-sm font-semibold text-yay-dark">{completedMessage}</p>;
  }
  return <></>;
});

const ProgressBarHeaders = memo(() => {
  const { milestones } = useProgressBar();
  return (
    <>
      {milestones.map((milestone, index) => {
        return <ProgressBarHeader key={milestone.milestone} index={index} />;
      })}
    </>
  );
});

export const ProgressBarStep = memo(({ index }: { index: number }) => {
  const { achievedMilestoneIdx, totalMilestones, milestones, type: progressType } = useProgressBar();
  const { milestone_type, milestone_amount, milestone_text } = milestones[index];
  const hasAchievedMileStone = achievedMilestoneIdx >= index;
  let Icon = MILESTONE_TO_ICON_MAPPER[milestone_type];
  // const width = totalMilestones === 1 ? "25%" : 100 / totalMilestones + "%";

  let left = `${Math.floor((100 / totalMilestones) * (index + 1))}%`;
  if (progressType === PROGRESS_BAR_TYPE.DYNAMIC) {
    left = "100%";
  }
  return (
    <li
      style={{
        left,
      }}
      className={classNames(
        "relative flex h-[72px]  flex-col items-center text-xxs font-medium",
        hasAchievedMileStone ? "text-yay-dark" : "text-gray-dark",
      )}>
      <div className="absolute flex w-[100px] flex-col items-center justify-center">
        <p className="">₹{milestone_amount}</p>
        <Icon
          size="48"
          viewBox="-12 -12 48 48"
          className={classNames(
            "mb-4 mt-3 h-5 w-5 rounded-full bg-white",
            hasAchievedMileStone
              ? "bg-yay-dark text-white shadow-sm"
              : "border-[1px] border-carbon-lighter text-[#c1c1c1]",
          )}
        />
        <p className="w-full truncate text-center">{milestone_text}</p>
      </div>
    </li>
  );
});

const StaticProgressBarSteps = memo(() => {
  const { milestones } = useProgressBar();
  return (
    <>
      {milestones.map((milestone, index) => {
        return <ProgressBarStep key={milestone.milestone} index={index} />;
      })}
    </>
  );
});

const DynamicProgressBarSteps = memo(() => {
  const { achievedMilestoneIdx, milestones } = useProgressBar();

  let dynamicMilestoneIdx = achievedMilestoneIdx + 1;
  if (dynamicMilestoneIdx === milestones.length) {
    dynamicMilestoneIdx = achievedMilestoneIdx;
  }

  return <ProgressBarStep index={dynamicMilestoneIdx} />;
});

const ProgressBar = ({
  total,
  milestones,
  type,
  completedMessage,
}: Omit<ProgressBarContainerProps, "children">): React.ReactElement => {
  const steps = useMemo(() => {
    switch (type) {
      case PROGRESS_BAR_TYPE.DYNAMIC:
        return <DynamicProgressBarSteps />;
      case PROGRESS_BAR_TYPE.STATIC:
        return <StaticProgressBarSteps />;
    }
  }, [type, milestones]);

  return (
    <ProgressBarContainer
      milestones={milestones}
      total={total}
      type={type}
      completedMessage={completedMessage}>
      <ProgressBarHeaders />

      <div className="w-[90%]">
        <ProgressBarSteps>{steps}</ProgressBarSteps>
      </div>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
