import { useCallback, Suspense } from "react";
import BillingSummaryDialog from "components/cart/dialogs/BillingDialog";
import CartAuthenticationDialog from "components/cart/dialogs/CartAuthenticationDialog";
import CartOtpVerficationDialog from "components/cart/dialogs/CartAuthenticationOtpVerificationDialog";
import { ItemCard } from "components/checkout/CheckoutItems";
import GenericBannerStrip from "components/common/notification-strips/GenericBannerStrip";
import { useLocale } from "lib/hooks/useLocale";
import { useEffect, useRef, useState } from "react";
import { ItemType } from "lib/types/checkout";
import { X } from "react-feather";
import { ShoppingCart } from "assests/icons/CartIcons";
import { AccordionRefType } from "components/common/Accordian";

import TrustBadgeGray from "assests/images/trust-badge-gray.svg";
import cartImage from "assests/images/cart.png";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { CartDialogType } from "lib/types/cart";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useSearchParams } from "react-router-dom";
import { getTotalItems, hasAutoAppliedCoupon, initializeCart } from "lib/utils/checkout";
import { currencyFormatter } from "lib/utils/formatters";
import Skeleton from "components/common/loaders/Skeleton";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import ProgressBar from "components/cart/progress-bar/ProgressBar";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";

import {
  classNames,
  closeIframe,
  inIframe,
  isEmptyObj,
  isThirdPartyCookieEnabled,
  publishPostMessage,
} from "lib/utils/helpers";
import { eventTypes, analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { parseUserData } from "lib/utils/user";
import { UserType } from "lib/types/user";
import { putRequest } from "lib/core/apiClient";
import Coupons from "components/checkout/Coupons";
import { errorToast } from "lib/utils/toasters";
import UpSellSection from "components/upsell/UpSellSection";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";

const Cart: React.FC = () => {
  const [searchParams] = useSearchParams();

  const authContext = useAuthContext();
  const {
    state: { isAuthenticated },
    actions: { logout, login },
  } = authContext;

  const userContext = useUserContext();
  const {
    state: { marketingConsent },
    actions: { setUserData },
  } = userContext;

  const checkoutContext = useCheckoutContext();
  const {
    state: { checkoutItems, billing, appliedCoupons, checkoutId, checkoutUIMetadata, checkoutModal },
    actions: { updateCheckoutBasedOnCheckoutResponse, setCheckoutModal },
  } = checkoutContext;

  const merchantContext = useMerchantContext();
  const {
    state: { merchant },
  } = merchantContext;

  const analytics = useSendAnalyticsEvent();

  const [dialog, setDialog] = useState<CartDialogType>(null);

  const { t } = useLocale();

  const accordionRef = useRef<AccordionRefType>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading && (!checkoutItems || checkoutItems?.length === 0)) {
      closeIframe();
    }
  }, [checkoutItems, isLoading]);

  useEffect(() => {
    setTimeout(() => {
      accordionRef.current?.openAccordion();
    }, 3000);
  }, []);

  const [isCartLoaded, setIsCartLoaded] = useState(false);
  useEffect(() => {
    if (!isCartLoaded) {
      initialize().then(() => {
        setIsCartLoaded(true);
      });
    }
  }, [isCartLoaded]);

  useEffect(() => {
    function openCart(event: any) {
      if (event?.data?.type === eventTypes.CART_OPEN && !isLoading) {
        initialize(Boolean(checkoutId));
      }
      if (event?.data?.type === eventTypes.LOGOUT_FROM_CHECKOUT) {
        logout(true);
      }
      if (event?.data?.type === eventTypes.LOGIN_FROM_CHECKOUT) {
        if (event?.data?.payload) {
          const { idToken, refreshToken, authExpiry, refreshExpiry } = event.data.payload;
          login(idToken, refreshToken, authExpiry, refreshExpiry);
        }
      }
    }
    window.addEventListener("message", openCart);

    return () => {
      window.removeEventListener("message", openCart);
    };
  }, [isLoading, isAuthenticated, checkoutId]);

  useEffect(() => {
    history.pushState({ cart: "ongoing" }, "");
  }, []);

  const backButtonHandler = useCallback(() => {
    if (checkoutModal !== "NONE") {
      setCheckoutModal("NONE");
      history.pushState({ cart: "close_modal" }, "");
      return;
    }
    publishPostMessage(eventTypes.CLOSE_CART_IFRAME, {});
  }, [checkoutModal]);

  useEffect(() => {
    window.addEventListener("popstate", backButtonHandler);
    return () => {
      window.removeEventListener("popstate", backButtonHandler);
    };
  }, [backButtonHandler]);

  const initialize = async (isUpdate: boolean = false) => {
    setIsLoading(true);
    try {
      const response = await initializeCart({
        tokenId: searchParams.get("tokenId"),
        analytics,
        userContext,
        authContext,
        checkoutContext,
        merchantContext,
        isUpdate,
      });
      if (response !== undefined) {
        const { checkoutResponse, accountResponse, tokenData } = response;
        handleResponseSuccess(checkoutResponse, accountResponse, tokenData);
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 403) logout(true);
      setIsLoading(false);
    }
  };

  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any, tokenData: any) => {
    handleAccountResponse(accountResponse, checkoutResponse, tokenData);
    handleCheckoutResponse(checkoutResponse);
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, false, true);
    updateMarketingConsent();
  };

  const updateMarketingConsent = async () => {
    if (marketingConsent === undefined) return;
    if (!Boolean(isAuthenticated)) return;
    try {
      await putRequest("/attributes/v1/account-attributes", {
        attributes: {
          marketing_consent: marketingConsent,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccountResponse = (accountResponse: any, checkoutResponse: any, tokenData: any) => {
    if (!Boolean(accountResponse) || isEmptyObj(accountResponse)) {
      return;
    }

    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserData(parsedUserData);
  };

  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const mainContent = mainContentRef.current;
    if (!mainContent) return;

    const checkIfScrollable = () => {
      setIsScrollable(mainContent.scrollHeight > mainContent.clientHeight);
    };

    checkIfScrollable();
  });

  const totalItems = getTotalItems(checkoutItems);
  if (isLoading && !checkoutId) {
    return <Skeleton />;
  }

  return (
    <div className="items-between flex h-full flex-col bg-white">
      <CartHeader totalItems={totalItems} />
      {checkoutItems?.length > 0 ? (
        <>
          {checkoutUIMetadata && checkoutUIMetadata.progressBarConfig.isEnabled && (
            <>
              <div className="px-4 pt-3">
                <ProgressBar
                  total={billing?.sub_total}
                  milestones={checkoutUIMetadata?.progressBarConfig.milestones}
                  type={checkoutUIMetadata.progressBarConfig.type}
                  completedMessage={checkoutUIMetadata.progressBarConfig.completedMessage}
                />
              </div>
              <hr className="mt-3 border-2 border-[#f2f2f2]" />
            </>
          )}
          <GenericBannerStrip />
          <div className="flex flex-1 flex-col gap-3 overflow-auto px-3 pt-3" ref={mainContentRef}>
            <CartItems
              items={checkoutItems}
              disableOOSItems={false}
              checkoutItemsMutable={true}
              isUpdating={isLoading}
              setIsUpdating={setIsLoading}
            />
            {(hasAutoAppliedCoupon(appliedCoupons) || Boolean(merchant?.isDiscountEnabled)) && (
              <Coupons context="cart" />
            )}
            {Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled) && (
              <Suspense fallback={<OverlaySpinner />}>
                <UpSellSection parent="CART" />
              </Suspense>
            )}
          </div>
          <CartFooter
            setDialog={setDialog}
            price={billing?.total_payable}
            checkoutId={checkoutId}
            isLoading={isLoading}
            showShadow={isScrollable}
          />

          <BillingSummaryDialog
            open={dialog === "billingDetails"}
            setDialog={setDialog}
            isLoading={isLoading}
          />
          <CartAuthenticationDialog open={dialog === "authentication"} setDialog={setDialog} />
          <CartOtpVerficationDialog open={dialog === "otpVerification"} setDialog={setDialog} />
        </>
      ) : (
        <div className="flex h-full w-full flex-col">
          <div className="flex flex-1 flex-col items-center justify-center space-y-6">
            <div className="flex flex-col items-center space-y-2">
              <img src={cartImage} className="" alt="cart" />
              <div className="text-sm font-medium text-coal-light ">{t("cart_is_empty")}</div>
            </div>
            <PrimaryButton
              buttonText={t("continue_shopping")}
              containerClassName="p-4"
              width="w-[228px]"
              isLoading={isLoading}
              onClick={() => publishPostMessage(eventTypes.CLOSE_CART_IFRAME, {})}
            />
          </div>
          <img src={TrustBadgeGray} className="mb-14 h-4" alt={"shopflo-logo"} />
        </div>
      )}
      {/*isUpdate && <OverlaySpinner /> */}
    </div>
  );
};

export default Cart;

function CartHeader({ totalItems }: { totalItems?: number }) {
  const { t } = useLocale();
  return (
    <div className="flex w-full items-center justify-between border-b px-6 pb-4 pt-9">
      <div className="flex h-6 items-center gap-2 font-medium text-coal-dark">
        <ShoppingCart className="h-4 w-4" />
        <div className="flex gap-1">
          <span>{t("your_cart")}</span>
          {Boolean(totalItems) && (
            <>
              <span>·</span>
              <span className="font-normal text-coal-light">{t("n_items", { items: totalItems })}</span>
            </>
          )}
        </div>
      </div>
      <button
        onClick={() => {
          publishPostMessage(eventTypes.CLOSE_CART_IFRAME, {});
        }}>
        <X className="h-6 w-6 text-coal-dark" />
      </button>
    </div>
  );
}

function CartItems({
  items,
  disableOOSItems,
  checkoutItemsMutable,
  isUpdating = false,
  setIsUpdating,
}: {
  items: any[];
  disableOOSItems: boolean;
  checkoutItemsMutable: boolean;
  isUpdating?: boolean;
  setIsUpdating?: (value: boolean) => void;
}) {
  return (
    <ul className="flex w-full flex-col space-y-3">
      {items?.map((item: ItemType, index: number) => (
        <li key={`${item.item_id}_${index}`}>
          <ItemCard
            {...item}
            disableOOSItems={disableOOSItems}
            checkoutItemsMutable={checkoutItemsMutable}
            isCartItem={true}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
          />
        </li>
      ))}
    </ul>
  );
}

function CartFooter({
  setDialog,
  price,
  checkoutId,
  isLoading = false,
  showShadow,
}: {
  setDialog: (dialog: CartDialogType) => void;
  price?: number;
  checkoutId: string;
  isLoading?: boolean;
  showShadow?: boolean;
}) {
  const {
    state: { isAuthenticated },
  } = useAuthContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { t } = useLocale();

  const handleAuthenticated = async () => {
    try {
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_CHECKOUT_CLICKED,
        eventType: "click",
      });
      await putRequest(`/checkout/v1/cart/${checkoutId}/checkout`, {});
      publishPostMessage(eventTypes.CART_TO_CHECKOUT, { checkoutId });
    } catch (error) {
      console.error("error in going to checkout", error);
      errorToast("Failed loading checkout");
    }
  };

  const handleCheckoutClick = () => {
    if (isAuthenticated) {
      handleAuthenticated();
      return;
    }
    if (inIframe() && !isThirdPartyCookieEnabled()) {
      let redirectUrl = new URL(document.location.href);
      let checkoutUrlSearchParams = new URLSearchParams(redirectUrl.search);
      checkoutUrlSearchParams.delete("page");
      checkoutUrlSearchParams.append("checkoutId", checkoutId);
      redirectUrl.search = checkoutUrlSearchParams.toString();
      publishPostMessage(eventTypes.PARENT_REDIRECT, {
        redirectUrl: redirectUrl.href,
        clearCart: false,
      });
      return;
    }
    setDialog("authentication");
  };

  return (
    <div
      className={classNames(
        "flex flex-col space-y-3 px-3 pb-14 pt-4",
        showShadow ? "shadow-[0px_-1px_8px_0px_#0000001A]" : "",
      )}>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className=" text-[18px] font-semibold text-coal-dark">{currencyFormatter(price)}</div>
          <button
            className="text-left text-sm text-coal-dark underline decoration-dashed underline-offset-2"
            onClick={() => {
              setDialog("billingDetails");
            }}>
            {t("estimated_total")}
          </button>
        </div>
        <PrimaryButton
          buttonText="Checkout"
          containerClassName="w-2/3 xxs:w-1/2"
          onClick={handleCheckoutClick}
          isLoading={isLoading}
        />
      </div>
      <img src={TrustBadgeGray} className="h-4" alt={"shopflo-logo"} />
    </div>
  );
}
