import { postRequest, getRequest, putRequest } from "lib/core/apiClient";
import { getDeviceInfo, getQueryParams, utmParamKeys } from "lib/utils/helpers";
import { isInstagramBrowser } from "lib/utils/helpers";
import { PaymentModeType } from "lib/types/payments";
import { paymentModeNextStep, isDefaultImplementationMode } from "lib/utils/payments";

export const validatePayment = async (
  checkoutId: string,
  paymentMode: PaymentModeType,
  merchantId: string,
  merchantName: string,
  { ...rest },
  sendAnalyticsEvent?: any,
) => {
  try {
    const response = await getRequest(`/checkout/v1/checkout/${checkoutId}/payments/status`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getPaymentStatus = (checkoutId: string) =>
  new Promise((resolve, reject) => {
    try {
      getRequest(`/checkout/v1/checkout/${checkoutId}/payments/status`).then((response) => resolve(response));
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

export const getDetailsByZip = async (zip: string) => {
  if (!Boolean(zip)) return {};
  try {
    const response = await getRequest(`/v1/pincodes/${zip}`, "CHECKOUT_PUBLIC");
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * Method to check if Default Razorpay gateway or Custom implementation of Razorpay to be loaded.
 * The check is based on the past failue rates of the loaded device.
 * Default Razorpay payment gateway will be loaded if failur rate on a particular device exceeds the configured threshold value.
 *
 * @return {boolean} true/false   true if Default Razorpay gateway to be loaded, false if custom implementation to be loaded
 */
export const isRzpModalRequired = async () => {
  if (isInstagramBrowser()) return true;
  return false;
  // const deviceInfo = getDeviceInfo();
  // try {
  //   const response = await getRequest(`/devices/${deviceInfo}/compatibility`, true);
  //   const threshold = constants.PAYMENT_FAILURE_THRESHOLD;
  //   return response?.count > threshold;
  // } catch (e) {
  //   console.error(e);
  //   throw e;
  // }
};

/**
 * Method to Sync Checkout Prices based on the sync_prices flag returned by the first checkout response.
 *
 * @param {string} checkoutId Checkout ID
 *
 * @return {checkoutResponse} checkoutResponseObj   Returns Checkout Response object
 */
export const checkoutSyncPrices = async (checkoutId: string) => {
  try {
    const checkoutResponse = await putRequest(
      `/checkout/v1/checkout/${checkoutId}/sync-prices`,
      {},
      "CHECKOUT",
      3,
    );
    return checkoutResponse;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * Method to put a verification tag for the placed order.
 *
 * @return {response}    Returns verfication response object
 */
export const setVerifiedFlag = async (addressId: string | undefined) => {
  try {
    const response = await putRequest(`/gatekeeper/v1/accounts/addresses/${addressId}/verify`, {});
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * Method to update UTM parameters for ACR checkouts
 *
 * @param  {string} checkoutId          checkout ID
 */
export const sendCheckoutUTMParameters = async (checkoutId: string) => {
  if (!Boolean(checkoutId)) return;
  try {
    const queryParams = window.location.search;
    let utmParams = [];
    if (
      utmParamKeys?.some(function (param) {
        return queryParams.indexOf(param) >= 0;
      })
    ) {
      utmParams = getQueryParams(queryParams);
      utmParams = utmParams?.filter((param: any) => utmParamKeys.includes(param.name));
    }

    if (!Boolean(utmParams?.length)) return;
    const payload = {
      note_attributes: utmParams,
    };

    await putRequest(`/checkout/v1/checkout/${checkoutId}/note-attributes`, payload);
  } catch (e) {
    console.error(e);
    return;
  }
};

/**
 * Method to get Address fields config by Country Code
 *
 * @param  {string} countryCode          Country Code
 *
 * @param  {string} merchantId           Merchant ID
 */
export const getAddressConfigByCountryCode = async (countryCode: string, merchantId: string) => {
  if (!countryCode || !merchantId) return {};
  try {
    const response = await getRequest(
      `/v1/merchants/${merchantId}/address-config?country_code=${countryCode}`,
      "CHECKOUT_PUBLIC",
    );
    return response;
  } catch (e) {
    console.error(e);
    return {};
  }
};

/**
 * Method to get Provinces/State Codes by Country Code
 *
 * @param  {string} countryCode          Country Code
 *
 */
export const getProvincesByCountryCode = async (countryCode: string) => {
  if (!countryCode) return [];
  try {
    const response = await getRequest(`/v1/countries/${countryCode}/provinces`, "CHECKOUT_PUBLIC");
    return response;
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * Initiates a payment method action by calling the backend API.
 *
 * @param method - The selected payment mode
 * @param checkoutId - The unique identifier of the current checkout
 *
 * @returns A promise that resolves to the response from the API or undefined if arguments are missing.
 * @throws An error if the API call fails.
 */
export const getPaymentMethodAction = async (
  method: PaymentModeType,
  checkoutId: string,
  context?: "SPLIT_COD",
  additionalPayload?: any,
  setCheckoutExpired?: (expired: boolean) => void,
): Promise<any | undefined> => {
  if (!checkoutId || !method) {
    // Return early with an undefined value if required arguments are missing.
    return;
  }

  if (method === "SPLIT_COD") {
    let paymentResponse;
    try {
      paymentResponse = await getRequest(
        `/checkout/v2/checkout/${checkoutId}/payments?mode_context=SPLIT_COD`,
      );
    } catch (e) {
      console.error(e);
    } finally {
      return paymentResponse;
    }
  }

  const payload = {
    payment_mode: method,
    action: paymentModeNextStep(method),
    ...(isDefaultImplementationMode(method) && { implementation: "DEFAULT" }),
    ...(context && { payment_context: context }),
    ...additionalPayload,
  };

  try {
    const response = await postRequest(`/checkout/v2/checkout/${checkoutId}/payments/initiate`, payload);
    return response;
  } catch (error: any) {
    if (
      error?.response?.status === 400 &&
      (error?.response?.data?.error === "C2P flow timedout" ||
        error?.response?.data?.error === "C2P is already completed")
    ) {
      setCheckoutExpired?.(true);
    }
    console.error("Error while getting Payment Method details: ", error);
    throw new Error(`Failed to initiate payment. ${error}`);
  }
};
