import { createContext, memo, useContext } from "react";
import { Milestone, PROGRESS_BAR_TYPE } from "lib/types/checkout";

export interface MileStoneProps {
  milestone: number;
  header: string;
  title: string;
  type: string;
  amount: number;
}

export interface ProgressBarContext {
  milestones: Milestone[];
  type: PROGRESS_BAR_TYPE;
  total: number;
  completedMessage: string;
  achievedMilestoneIdx: number;
  totalMilestones: number;
  currentProgress: number;
}

const ProgressContext = createContext<ProgressBarContext>({
  milestones: [],
  total: 0,
  type: PROGRESS_BAR_TYPE.DYNAMIC,
  completedMessage: "",
  achievedMilestoneIdx: 0,
  totalMilestones: 0,
  currentProgress: 0,
});

interface ProgressBarProviderProps extends ProgressBarContext {
  children: React.ReactNode;
}

export const ProgressBarProvider = memo(
  ({
    children,
    milestones,
    achievedMilestoneIdx = 1,
    totalMilestones,
    currentProgress,
    type,
    total,
    completedMessage,
  }: ProgressBarProviderProps) => {
    return (
      <ProgressContext.Provider
        value={{
          achievedMilestoneIdx,
          totalMilestones,
          currentProgress,
          milestones,
          type,
          total,
          completedMessage,
        }}>
        {children}
      </ProgressContext.Provider>
    );
  },
);

export const useProgressBar = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error("useProgressBar must be used within a ProgressBarProvider");
  }
  return context;
};
